@use "sass:map";
@import "./variables";

.swiperCalendarPagination {
  margin-top: 30px;
  text-align: center;

  .swiper-pagination-bullet {
    height: 10px;
    width: 10px;
    background-color: #000;
    opacity: 1;

    &.swiper-pagination-bullet-active {
      background-color: map.get($theme-colors, "gold");
    }
  }
}

.imageCarouselSwipe {
  .swiper-slide {
    max-width: 320px;
  }
}

.swipeImagesPagination {
  text-align: center;
  margin-top: 10px;
  .swiper-pagination-bullet {
    height: 10px;
    width: 10px;
    border: solid 1px map.get($theme-colors, "gold");
    background-color: transparent;
    opacity: 1;

    &.swiper-pagination-bullet-active {
      background-color: map.get($theme-colors, "gold");
    }
  }
}