@use "sass:map";
@import "./variables";

@import "../node_modules/react-datepicker/src/stylesheets/datepicker.scss";

.react-datepicker {
    font-family: $mulish-font-family  !important;
    font-weight: $mulish-fw-semi-bold;
    border-radius: 0 !important;
    width: 100%;
    border: 2px solid map.get($theme-colors, "medium-gold");

    @include breakpoint-under("sm") {
        width: 155%;
    }
}

.react-datepicker-wrapper.datepicker,
.react-datepicker__input-container {
    height: 100% !important;
}

.react-datepicker__triangle {
    display: none;
}

.react-datepicker__navigation {
    margin: 0.75rem 0 !important;
}

.react-datepicker__navigation-icon::before {
    border-color: map.get($theme-colors, "extra-dark") !important;
    border-width: 2px 2px 0 0 !important;
    //top: -15px !important;
    height: 5px !important;
    width: 5px !important;
}

.react-datepicker__navigation--previous {
    left: 25% !important;
}

.react-datepicker__navigation--next {
    right: 25% !important;
}

.react-datepicker-popper,
.react-datepicker__month-container {
    width: 100%;
    top: 100% !important;
    padding: 0 !important;
    transform: translate(-2px, 0px) !important;
}

.react-datepicker__header {
    background-color: #fff !important;
    border: none !important;
    border-radius: 0 !important;
    padding: 0 !important;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: auto;
    width: 80%;

    select {
        padding: 0.2rem 0.2rem;
        border: 1px solid #857e70;
        border-radius: 0.5rem;
        font-family: "Mulish", sans-serif;
        font-weight: bold;
        margin-left: 0.3rem;
        margin-right: 0.3rem;
        font-size: .9rem;
    }

    button {
        color: #857e70;
        font-weight: bold;
        display: none;
    }
}

.react-datepicker__current-month {
    padding: 1rem 0 !important;
}

.react-datepicker__day-name {
    text-transform: uppercase;
    margin-bottom: 0 !important;
    color: map.get($theme-colors, "medium-gray") !important;
}

.react-datepicker__day {
    border-radius: 20px !important;

    &:hover {
        background-color: map.get($theme-colors, "dark-gray") !important;
        color: map.get($theme-colors, "extra-dark") !important;
    }
}

.react-datepicker__day--outside-month {
    color: map.get($theme-colors, "dark-gray") !important;

    &.react-datepicker__day--selected {
        color: map.get($theme-colors, "light") !important;
    }
}

.react-datepicker__day--selected {
    background-color: map.get($theme-colors, "gold") !important;
}

.react-datepicker__day--keyboard-selected {
    background-color: map.get($theme-colors, "light-gray") !important;
}