@use "sass:map";
@import "./media_queries";

.homeCarousel, .experienceCarousel, .headCarousel {
    .swiper-button-next {
        background: url("../public/assets/img/rightGoldArrow.png") map.get($theme-colors, "extra-dark");
        background-repeat: no-repeat;
        background-size: 50%;
        background-position: center;
        margin: 0 2rem 0 0;
        border-radius: 0px;
        height: 72px;
        width: 72px;
        border-radius: 50px;
        transition: all 0.2s ease;
        margin: auto;

        @include breakpoint-under("lg") {
            display: none;
        }

        @include breakpoint-over("lg") {
            &:hover {
                background: url("../public/assets/img/rightDarkArrow.png") map.get($theme-colors, "gold");
                background-repeat: no-repeat;
                background-position: center;
            }
        }

        &::after {
            display: none;
        }
    }

    .swiper-button-prev {
        background: url("../public/assets/img/rightGoldArrow.png") map.get($theme-colors, "extra-dark");
        transform: rotate(180deg);
        background-repeat: no-repeat;
        background-size: 50%;
        background-position: center;
        margin: 0 0 0 2rem;
        height: 72px;
        width: 72px;
        border-radius: 50px;
        transition: all 0.2s ease;
        margin: auto;

        @include breakpoint-under("lg") {
            display: none;
        }

        @include breakpoint-over("lg") {
            &:hover {
                background: url("../public/assets/img/rightDarkArrow.png") map.get($theme-colors, "gold");
                background-repeat: no-repeat;
                background-position: center;
                transform: rotate(180deg);
            }
        }

        &::after {
            display: none;
        }
    }
}

.headCarousel{
    .swiper-button-next {
        right: 0px;
    }

    .swiper-button-prev {
        left: 0px;
    }
}

.homeCarousel{
    .swiper-button-next{
        top: 25%;
        right: calc((100% - 1280px)/2);

        @include breakpoint-under("xxl"){
            right: 0px;
        }

        @include breakpoint-under("md"){
            background: url("../public/assets/img/rightGoldArrow.png");
            display: block !important;
            border-radius: 0%;
            background-repeat: no-repeat;
            width: 34px;
            height: 11px;
            transform: scale(1.1);
            bottom: -165px;
        }

        @include breakpoint-under("sm"){
            
            bottom: -228px;
        }
    }

    .swiper-button-prev{
        top: 25%;
        left: calc((100% - 1280px)/2);

        @include breakpoint-under("xxl"){
            left: 0px;
        }

        @include breakpoint-under("md"){
            background: url("../public/assets/img/rightGoldArrow.png");
            transform: rotate(180deg) scale(1.1);
            display: block !important;
            border-radius: 0%;
            background-repeat: no-repeat;
            width: 34px;
            height: 11px;
            bottom: -165px;
        }

        @include breakpoint-under("sm"){
            bottom: -228px;
        }
    }
}
