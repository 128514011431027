@use "sass:map";

.headCarousel {
    .swiper {
        width: 100%;
        height: 100%;
        overflow: visible;
    }

    .swiper-slide {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        margin: 0px !important;
        transition: all 0.3s ease;
        transform: scale(0.6) !important;
        
        @include breakpoint-under("md") {
            transform: scale(0.425) !important;
        }

        &.swiper-slide-active {
            transform: scale(1) !important;
            z-index: 2;
            @include breakpoint-under("md") {
                transform: scale(.85) !important;
            }
        }
    }

    .swiper-slide-next {
        z-index: 2;

        .carouselPic::before {
            background: none;
        }
    }

    .carouselPic {
        position: relative;

        &::before {
            content: "";
            position: absolute;
            bottom: -40px;
            left: 0;
            height: 300px;
            width: 100%;
            background: linear-gradient(to top, map.get($theme-colors, "extra-dark") 25%, transparent 100%);
            @include breakpoint-under("lg") {
                height: 90px;
                background: linear-gradient(to top, map.get($theme-colors, "extra-dark") 50%, transparent 100%);
            }
        }
    }
}
