@use "sass:map";
@import "../styles/variables";

.experienceCarousel {
  .afterDark {
    .swiper-slide {
      flex-shrink: 1 !important;
    }
    .swiper-button-prev {
      left: calc((100% - 1380px) / 2);

      @include breakpoint-under("xxl") {
        left: 0px;
      }
    }

    .swiper-button-next {
      right: calc((100% - 1380px) / 2);

      @include breakpoint-under("xxl") {
        right: 0px;
      }
    }

    .swiper-slide-active {
      &::before {
        content: "";
        z-index: 1;
        position: absolute;
        bottom: 0;
        left: 0;
        height: 100px;
        width: 100%;
        background: linear-gradient(
          to top,
          map.get($theme-colors, "special2") 25%,
          transparent 100%
        );
        @include breakpoint-under("md") {
          background: linear-gradient(
            to top,
            map.get($theme-colors, "special2") 50%,
            transparent 100%
          );
        }
      }
    }

    .swiper-slide-prev,
    .swiper-slide-next {
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: linear-gradient(
          0deg,
          map.get($theme-colors, "special2") 18.95%,
          rgba(0, 0, 0, 0.51) 60.93%
        );
        // @include breakpoint-under("md") {
        //     height: 0px;
        //     // background: none;
        // }
      }
    }
  }

  .experienceMobile {
    .swiper-slide {
      flex-shrink: 1 !important;
    }

    .swiper-slide-prev,
    .swiper-slide-next {
      opacity: .5;
    }
  }
}
