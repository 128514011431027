@use "sass:map";
@import "variables";
@import "media_queries";

// Buttons
.btn-mall {
  padding: 0.5rem 4rem;
  border: none;

  &.btn-mall-gold {
    color: #000;
    background-color: map.get($theme-colors, "gold");
    @include unna-italic;
    font-size: 1.25rem;
  }

  &.btn-mall-outline-dark {
    color: map.get($theme-colors, "gold");
    background-color: map.get($theme-colors, "dark");
    @include unna-italic;
    font-size: 1.25rem;
    border: 1px solid map.get($theme-colors, "gold");
  }

  &.btn-mall-outline-medium-dark {
    color: map.get($theme-colors, "gold");
    background-color: map.get($theme-colors, "medium-dark");
    @include unna-italic;
    font-size: 1.25rem;
    border: 1px solid map.get($theme-colors, "gold");
  }

  &.btn-mall-outline-extra-dark {
    color: map.get($theme-colors, "gold");
    background-color: map.get($theme-colors, "extra-dark");
    @include unna-italic;
    font-size: 1.25rem;
    border: 1px solid map.get($theme-colors, "gold");
  }
}

// Layouts
.centeredSection {
  max-width: 1280px;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.smallerCenteredSection {
  max-width: 995px;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.cardGrid {
  margin-top: 3.625rem;
  margin-bottom: 8.3rem;
  @include breakpoint-under("md") {
    position: relative;
    margin-top: 2.06rem;
    margin-bottom: 2.375rem;
  }
}

a {
  text-decoration: none;
  color: inherit;
}

.scroll {
  display: flex;
  margin-bottom: 5rem;
}

a,
div,
button {
  -webkit-tap-highlight-color: transparent;
}

.myInformationCompleteBanner {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  img {
    width: 100%;
    height: auto;
  }
  img.complete_profile_mobile {
    display: none;
  }
  @include breakpoint-under("md") {
    img.complete_profile {
      display: none;
    }
    img.complete_profile_mobile {
      display: block;
    }
  }
  svg {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}
.myAreaLayout {
  display: flex;
  padding-top: 3.125rem;
  background-color: $bg-outlet-website;
  overflow: hidden;
  color: map.get($theme-colors, "extra-dark");
  padding-right: 1rem;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;

  @include breakpoint-under("xl") {
    padding-right: 2rem;
  }

  @include breakpoint-under("lg") {
    flex-direction: column;
    padding: 0;
    padding-top: 1.75rem;
  }

  .myAreaMenu {
    min-width: 368px;

    @include breakpoint-under("xl") {
      min-width: 205px;
    }
  }
}

.scroll {
  display: flex;
  margin-top: 5rem;
  margin-bottom: 4rem;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #D2D2D2 inset;
}



#zNasQsOa {
  position: relative;
  display: inline-flex;
  font-family: 'Mulish';
  align-items: stretch;

  input {
    background-color: #e9e9e9;
    height: 35px;
    padding-left: 15px;
    border: solid 1px #a9a9a9;
  }
  button {
    background-color: #b1a38a;
    padding-left: 5px;
    margin-left: 5px;
    padding-right: 5px;
    font-family: 'Mulish';
    border: solid 1px #b1a38a;
    color: #000;
  }
}
#zNasQsOares {
  background-color: #fff;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 20px;
}
#zNasQsOaok, #zNasQsOaerr {
  display: none;
}