@use "sass:map";
@import "../../styles/variables";

.topbanner {
  @include mulish-regular;
  margin: 0;
  padding: 1px;
  background-color: map.get($theme-colors, "gold");
  .topbannerContainer {
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;

    .content {
      padding: 10px;
      text-align: center;
      p {
        margin: 0;
        display: inline;

        a {display: inline-block; text-decoration: underline;}
      }
    }
  }
}