@use "sass:map";
@import "../../styles/variables";
@import "../../styles/_media_queries";

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    box-sizing: border-box;
    padding-left: 15px;
    padding-right: 15px;
    height: 100vh;
    z-index: 200;
    background-color: rgba(0,0,0,0.85);
    text-align: center;
    overflow: auto;
    z-index: 10001;

    .PopupContainer {
      max-width: 380px;
      background-color: #fff;
      margin-top: 100px;
      margin-bottom: 100px;
      margin-left: auto;
      margin-right: auto;
      position: relative;

      .topImage {
        img {
          width: 100%;
          height: auto;

          &.mobile {
            display: none;
          }
        }
        @include breakpoint-under("md") {
          img {
            display: none;

            &.mobile {
              display: block;
            }
          }
        }
      }

      .shortClose {
        position: absolute;
        top: 15px;
        right: 15px;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100px;
        background-color: #fff;
      }

      .content {
        padding: 30px;

        .title {
          font-size: 1.5rem;
          @include unna-italic;
        }
        .text {
          @include mulish-light;
          text-align: center;
          margin: 1rem 0 2.5rem 0;
        }
      }
      .buttons {
        @include unna-italic;
        
        .longClose {
          margin-top: 1.6rem;
          display: inline-block;
          cursor: pointer;
          text-decoration: none;
        }
      }
    }
}