/* unna-regular - latin-ext_latin */
@font-face {
  font-family: 'Unna';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/unna-v21-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/assets/fonts/unna-v21-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/unna-v21-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/unna-v21-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/unna-v21-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/unna-v21-latin-ext_latin-regular.svg#Unna') format('svg'); /* Legacy iOS */
}
/* unna-italic - latin-ext_latin */
@font-face {
  font-family: 'Unna';
  font-style: italic;
  font-weight: 400;
  src: url('/assets/fonts/unna-v21-latin-ext_latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/assets/fonts/unna-v21-latin-ext_latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/unna-v21-latin-ext_latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/unna-v21-latin-ext_latin-italic.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/unna-v21-latin-ext_latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/unna-v21-latin-ext_latin-italic.svg#Unna') format('svg'); /* Legacy iOS */
}

/* mulish-300 - latin-ext_latin_cyrillic-ext */
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 300;
  src: url('/assets/fonts/mulish-v12-latin-ext_latin_cyrillic-ext-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/assets/fonts/mulish-v12-latin-ext_latin_cyrillic-ext-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/mulish-v12-latin-ext_latin_cyrillic-ext-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/mulish-v12-latin-ext_latin_cyrillic-ext-300.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/mulish-v12-latin-ext_latin_cyrillic-ext-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/mulish-v12-latin-ext_latin_cyrillic-ext-300.svg#Mulish') format('svg'); /* Legacy iOS */
}
/* mulish-regular - latin-ext_latin_cyrillic-ext */
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/mulish-v12-latin-ext_latin_cyrillic-ext-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/assets/fonts/mulish-v12-latin-ext_latin_cyrillic-ext-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/mulish-v12-latin-ext_latin_cyrillic-ext-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/mulish-v12-latin-ext_latin_cyrillic-ext-regular.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/mulish-v12-latin-ext_latin_cyrillic-ext-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/mulish-v12-latin-ext_latin_cyrillic-ext-regular.svg#Mulish') format('svg'); /* Legacy iOS */
}
/* mulish-600 - latin-ext_latin_cyrillic-ext */
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  src: url('/assets/fonts/mulish-v12-latin-ext_latin_cyrillic-ext-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/assets/fonts/mulish-v12-latin-ext_latin_cyrillic-ext-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/mulish-v12-latin-ext_latin_cyrillic-ext-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/mulish-v12-latin-ext_latin_cyrillic-ext-600.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/mulish-v12-latin-ext_latin_cyrillic-ext-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/mulish-v12-latin-ext_latin_cyrillic-ext-600.svg#Mulish') format('svg'); /* Legacy iOS */
}
/* mulish-300italic - latin-ext_latin_cyrillic-ext */
@font-face {
  font-family: 'Mulish';
  font-style: italic;
  font-weight: 300;
  src: url('/assets/fonts/mulish-v12-latin-ext_latin_cyrillic-ext-300italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/assets/fonts/mulish-v12-latin-ext_latin_cyrillic-ext-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/mulish-v12-latin-ext_latin_cyrillic-ext-300italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/mulish-v12-latin-ext_latin_cyrillic-ext-300italic.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/mulish-v12-latin-ext_latin_cyrillic-ext-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/mulish-v12-latin-ext_latin_cyrillic-ext-300italic.svg#Mulish') format('svg'); /* Legacy iOS */
}
/* mulish-italic - latin-ext_latin_cyrillic-ext */
@font-face {
  font-family: 'Mulish';
  font-style: italic;
  font-weight: 400;
  src: url('/assets/fonts/mulish-v12-latin-ext_latin_cyrillic-ext-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/assets/fonts/mulish-v12-latin-ext_latin_cyrillic-ext-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/mulish-v12-latin-ext_latin_cyrillic-ext-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/mulish-v12-latin-ext_latin_cyrillic-ext-italic.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/mulish-v12-latin-ext_latin_cyrillic-ext-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/mulish-v12-latin-ext_latin_cyrillic-ext-italic.svg#Mulish') format('svg'); /* Legacy iOS */
}
/* mulish-600italic - latin-ext_latin_cyrillic-ext */
@font-face {
  font-family: 'Mulish';
  font-style: italic;
  font-weight: 600;
  src: url('/assets/fonts/mulish-v12-latin-ext_latin_cyrillic-ext-600italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/assets/fonts/mulish-v12-latin-ext_latin_cyrillic-ext-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/mulish-v12-latin-ext_latin_cyrillic-ext-600italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/mulish-v12-latin-ext_latin_cyrillic-ext-600italic.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/mulish-v12-latin-ext_latin_cyrillic-ext-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/mulish-v12-latin-ext_latin_cyrillic-ext-600italic.svg#Mulish') format('svg'); /* Legacy iOS */
}

/* symbols */
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 100 700;
  font-display: block;
  src: url("/assets/fonts/material-symbols-outlined.woff2") format("woff2");
}
.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-feature-settings: "liga";
}

/* DM Sans */
/* dm-sans-regular - latin-ext_latin */
@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/dm-sans-v11-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/assets/fonts/dm-sans-v11-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/dm-sans-v11-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/dm-sans-v11-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/dm-sans-v11-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/dm-sans-v11-latin-ext_latin-regular.svg#DMSans') format('svg'); /* Legacy iOS */
}
/* dm-sans-italic - latin-ext_latin */
@font-face {
  font-family: 'DM Sans';
  font-style: italic;
  font-weight: 400;
  src: url('/assets/fonts/dm-sans-v11-latin-ext_latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/assets/fonts/dm-sans-v11-latin-ext_latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/dm-sans-v11-latin-ext_latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/dm-sans-v11-latin-ext_latin-italic.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/dm-sans-v11-latin-ext_latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/dm-sans-v11-latin-ext_latin-italic.svg#DMSans') format('svg'); /* Legacy iOS */
}
/* dm-sans-500 - latin-ext_latin */
@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  src: url('/assets/fonts/dm-sans-v11-latin-ext_latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/assets/fonts/dm-sans-v11-latin-ext_latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/dm-sans-v11-latin-ext_latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/dm-sans-v11-latin-ext_latin-500.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/dm-sans-v11-latin-ext_latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/dm-sans-v11-latin-ext_latin-500.svg#DMSans') format('svg'); /* Legacy iOS */
}
/* dm-sans-500italic - latin-ext_latin */
@font-face {
  font-family: 'DM Sans';
  font-style: italic;
  font-weight: 500;
  src: url('/assets/fonts/dm-sans-v11-latin-ext_latin-500italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/assets/fonts/dm-sans-v11-latin-ext_latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/dm-sans-v11-latin-ext_latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/dm-sans-v11-latin-ext_latin-500italic.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/dm-sans-v11-latin-ext_latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/dm-sans-v11-latin-ext_latin-500italic.svg#DMSans') format('svg'); /* Legacy iOS */
}
/* dm-sans-700 - latin-ext_latin */
@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  src: url('/assets/fonts/dm-sans-v11-latin-ext_latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/assets/fonts/dm-sans-v11-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/dm-sans-v11-latin-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/dm-sans-v11-latin-ext_latin-700.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/dm-sans-v11-latin-ext_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/dm-sans-v11-latin-ext_latin-700.svg#DMSans') format('svg'); /* Legacy iOS */
}
/* dm-sans-700italic - latin-ext_latin */
@font-face {
  font-family: 'DM Sans';
  font-style: italic;
  font-weight: 700;
  src: url('/assets/fonts/dm-sans-v11-latin-ext_latin-700italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/assets/fonts/dm-sans-v11-latin-ext_latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/dm-sans-v11-latin-ext_latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/dm-sans-v11-latin-ext_latin-700italic.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/dm-sans-v11-latin-ext_latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/dm-sans-v11-latin-ext_latin-700italic.svg#DMSans') format('svg'); /* Legacy iOS */
}


/* DM Serif Display */
/* dm-serif-display-regular - latin-ext_latin */
@font-face {
  font-family: 'DM Serif Display';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/dm-serif-display-v11-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/assets/fonts/dm-serif-display-v11-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/dm-serif-display-v11-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/dm-serif-display-v11-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/dm-serif-display-v11-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/dm-serif-display-v11-latin-ext_latin-regular.svg#DMSerifDisplay') format('svg'); /* Legacy iOS */
}
/* dm-serif-display-italic - latin-ext_latin */
@font-face {
  font-family: 'DM Serif Display';
  font-style: italic;
  font-weight: 400;
  src: url('/assets/fonts/dm-serif-display-v11-latin-ext_latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/assets/fonts/dm-serif-display-v11-latin-ext_latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/dm-serif-display-v11-latin-ext_latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/dm-serif-display-v11-latin-ext_latin-italic.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/dm-serif-display-v11-latin-ext_latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/dm-serif-display-v11-latin-ext_latin-italic.svg#DMSerifDisplay') format('svg'); /* Legacy iOS */
}