@use "sass:map";
@import "../styles/variables";
@import "../styles/media_queries";

.imageCarousel {
  .swiper-slide {
    max-width: 714px;
    max-height: 350px;

    img {
      // width: 100%;
    }

    @include breakpoint-under("md") {
      max-width: 339px;
      max-height: 302px;
      img {
        width: 400px;
      }
    }
  }
}

.imageCarouselSmall {
    .swiper-slide {
        max-width: 241px;
        max-height: 302px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
            // width: 400px;
        }
    }
}
