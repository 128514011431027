@import "../styles/media_queries";

.cardCarouselWithCards {
    .swiper-wrapper{
        width: fit-content !important;
        margin: 0 !important;
    }
    .swiper-slide {
        flex-shrink: 1 !important;
    }
}

.swiperCenter{
    @include breakpoint-over("xxl"){
        .swiper-wrapper{
            transform: none !important;
            margin: 0 auto !important;
        }
    }
}