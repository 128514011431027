@use "sass:map";
@import "../styles/_variables";
@import "../styles/_media_queries";

.formSelect {
  height: 100%;
  width: 198px;
  color: map.get($theme-colors, "extra-dark") !important;

  @include breakpoint-under("xl"){
    width: 100%;
  }

  .css-qc6sy-singleValue{
    @include mulish-bold;
    font-size: 1.06rem;
  }

  .css-1hb7zxy-IndicatorsContainer {
    span {
      display: none !important;
    }
  }

  .css-1okebmr-indicatorSeparator{
    display: none !important;
  }

  .css-b62m3t-container {
    @include mulish-light;
    height: 100% !important;

    .css-1d8n9bt, .css-319lph-ValueContainer {
      padding-left: 1.38rem;
    }

    .css-tlfecz-indicatorContainer {
      color: inherit !important;
      margin-right: 1rem;
      transform: scale(2) !important;
      transition: all 0.5s ease;
    }
    .css-1gtu0rj-indicatorContainer {
      color: inherit !important;
      margin-right: 1rem;
      transform: scale(2) rotate(180deg) !important;
      transition: all 0.5s ease;
    }
    .css-1s2u09g-control {
      height: 100% !important;
      border-radius: 0 !important;
      border: none;
      background-color: transparent;
    }
    .css-1pahdxg-control {
      height: 100% !important;
      border: none !important;
      border-radius: 0 !important;
      outline: 2px solid map.get($theme-colors, "medium-gold") !important;
      background-color: transparent;
    }

    .css-2613qy-menu {
      margin: 0 !important;
      padding: 0 !important;
    }
  }
  
  .css-1s2u09g-control{
    border: 1px solid rgba($color: map.get($theme-colors, "extra-dark"), $alpha: .3) !important;
  }
}

.biggerSelect{
  width: 419px;

  @include breakpoint-under("xxl"){
    width: 397px;
  }

  @include breakpoint-under("xl"){
    max-width: none;
    width: 100%;
  }
}
