@use "sass:map";

.homeCarousel {
  @include breakpoint-under("md") {
    .swiper {
      width: 100%;
      height: 100%;
      overflow: visible;
    }

    .swiper-slide {
      display: flex;
      justify-content: center;
      margin: 0px;
      transition: all 0.3s ease;
      transform: scale(0.6) !important;

      img {
        width: 500px !important;
        @include breakpoint-under("sm") {
          width: 334px !important;
        }
      }

      &.swiper-slide-active {
        bottom: auto;
        transform: scale(1) !important;
        z-index: 2;
      }

      &.swiper-slide-next {
        margin-left: 100px;
        @include breakpoint-under("sm") {
          margin-left: 70px;
        }
      }
    }

    .swiper-slide-next {
      z-index: 2;

      .carouselPic::before {
        background: none;
      }
    }

    .carouselPic {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        bottom: -40px;
        left: 0;
        // height: 300px;
        width: 100%;
        // background: linear-gradient(
        //   to top,
        //   map.get($theme-colors, "extra-dark") 25%,
        //   transparent 100%
        // );
        height: 50px;
        background: linear-gradient(
          to top,
          map.get($theme-colors, "extra-dark") 50%,
          transparent 100%
        );
      }
    }
  }

  .swiper-slide {
    img {
      width: 100%;
    }
  }
}
