@use "sass:map";
@import "_variables";

.text-gold {
    color: map.get($theme-colors, "gold");
}

.text-gray {
    color: map.get($theme-colors, "gray");
}

.text-light {
    color: map.get($theme-colors, "light");
}

.text-unna-italic {
    @include unna-italic;
}

.text-unna-regular {
    @include unna-regular;
}

.text-mulish-regular {
    @include mulish-regular;
}

.text-mulish-semi-bold {
    @include mulish-semi-bold;
}

@for $i from 1 through 30 {
    .font-size-#{$i} {
      font-size: $i + rem; 
    }
}
