@use 'sass:map';
@import '../../styles/variables';

.ButtonWithIcon {
    display: flex;
    flex-direction: row;
    font-size: 1.25rem;
    padding: .5rem 1rem;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: none;
    border: none;
    @include unna-italic;

    svg {
        display: block;
    }

    .icon {
        display: block !important;


        &.arrow {
            transform: rotate(-45deg);

            path {
                stroke: map.get($theme-colors, "dark");
            }
        }
    }

    &.empty {
        color: map.get($theme-colors, "dark");
        border: none !important;
        background: transparent !important;
        background-size: 200%;
        transition: all .5s ease !important;

        &:hover {
            background-color: map.get($theme-colors, "gold");
            background-position: left;
            color: map.get($theme-colors, "extra-dark");

            path {
                stroke: map.get($theme-colors, "extra-dark");
            }
        }
    }

    &.outline {
        color: map.get($theme-colors, "gold");
        border: 1px solid map.get($theme-colors, "gold");
        background: linear-gradient(to left, transparent 50%, map.get($theme-colors, "gold") 50%) right;
        background-size: 200%;
        transition: all .5s ease !important;

        &:hover {
            background-color: map.get($theme-colors, "gold");
            background-position: left;
            color: map.get($theme-colors, "extra-dark");

            path {
                stroke: map.get($theme-colors, "extra-dark");
            }
        }
    }

    &.full {
        line-height: 2;
        color: map.get($theme-colors, "extra-dark");
        border: 1px solid map.get($theme-colors, "gold");
        background: linear-gradient(to left, map.get($theme-colors, "gold") 50%, transparent 50%) right;
        background-size: 200%;
        transition: 0.5s ease-out;
        background-color: map.get($theme-colors, "gold");

        &:hover {
            background-position: left;
            background-color: transparent;
            border-color: map.get($theme-colors, "medium-gold");
            color: map.get($theme-colors, "medium-gold") !important;

            path {
                stroke: map.get($theme-colors, "medium-gold");
            }
        }
    }

    &.lightGold {
        &:hover {
            border-color: map.get($theme-colors, "gold");
            color: map.get($theme-colors, "gold") !important;

            path {
                stroke: map.get($theme-colors, "gold");
            }
        }
    }

    &.fill {
        &:hover {
            path {
                fill: map.get($theme-colors, "gold")
            }
        }
    }
}